html {
  margin: 0;
  padding: 0;
  height: 100vh;
  overscroll-behavior: none;
}

body {
  margin: 0;
  height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
  overflow-x: hidden;
}
